import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
    isCustomDesigns,
    isCustom2Design,
    isClassicDesign,
    isVersion6Design,
    isVersion6DesktopTarget,
    isVersion6MobileTarget,
} from '../../utils/platform';
import SpriteSvg from './SpriteSvg';

class SvgIcon extends Component {
    static ACCOUNT_HISTORY = 'ACCOUNT_HISTORY';
    static ACTIVITY_LOG = 'ACTIVITY_LOG';
    static ARROW_LEFT = 'ARROW_LEFT';
    static OK = 'OK';
    static CANCEL = 'CANCEL';
    static CLOSE = 'CLOSE';    
    static GRAF_CUSTOM2 = 'GRAF_CUSTOM2';
    static GRAPH = 'GRAPH';
    static SYNC = 'SYNC';
    static ALERT = 'ALERT';
    static USER = 'USER';
    static USER2 = 'USER2';
    static USER3 = 'USER3';
    static USER_CUSTOM2 = 'USER_CUSTOM2';
    static USER_SHIELD = 'USER_SHIELD';
    static OHLC_CHART = 'OHLC_CHART';
    static CANDLES_CHART = 'CANDLES_CHART';
    static CANDLES_CHART_CLASSIC = 'CANDLES_CHART_CLASSIC';
    static CHART_SETTINGS = 'CHART_SETTINGS';
    static CHART_SETTINGS1 = 'CHART_SETTINGS1';
    static LINE_CHART = 'LINE_CHART';
    static AREA_CHART = 'AREA_CHART';
    static HAIKIN_ASHI_CHART = 'HAIKIN_ASHI_CHART';
    static MAGNET_DEFAULT = "MAGNET_DEFAULT";
    static MAGNET1 = "MAGNET1";
    static VERSION6_MAGNET = "VERSION6_MAGNET";
    static MAGNET = isVersion6MobileTarget 
        ? SvgIcon.VERSION6_MAGNET
        : isVersion6DesktopTarget
        ? SvgIcon.MAGNET1
        : SvgIcon.MAGNET_DEFAULT;
    static ZOOM_IN_CLASSIC1 = 'ZOOM_IN_CLASSIC1';
    static ZOOM_IN_CUSTOM2 = 'ZOOM_IN_CUSTOM2';
    static RESET = 'RESET';
    static ZOOM_IN2 = 'ZOOM_IN2';
    static ZOOM_OUT2 = 'ZOOM_OUT2';
    static ZOOM_IN_CLASSIC = isCustom2Design
        ? SvgIcon.ZOOM_IN_CUSTOM2
        : isVersion6Design
        ? SvgIcon.ZOOM_IN2
        : SvgIcon.ZOOM_IN_CLASSIC1;
    static ZOOM_IN1 = 'ZOOM_IN1';
    static ZOOM_IN = isCustomDesigns
        ? SvgIcon.ZOOM_IN_CLASSIC
        : SvgIcon.ZOOM_IN1;
    static ZOOM_OUT_CLASSIC1 = 'ZOOM_OUT_CLASSIC1';
    static ZOOM_OUT_CUSTOM2 = 'ZOOM_OUT_CUSTOM2';
    static ZOOM_OUT_CLASSIC = isCustom2Design
        ? SvgIcon.ZOOM_OUT_CUSTOM2
        : isVersion6Design
        ? SvgIcon.ZOOM_OUT2
        : SvgIcon.ZOOM_OUT_CLASSIC1;
    static ZOOM_OUT1 = 'ZOOM_OUT1';
    static ZOOM_OUT = isCustomDesigns
        ? SvgIcon.ZOOM_OUT_CLASSIC
        : SvgIcon.ZOOM_OUT1;
    static CROSSHAIR = 'CROSSHAIR';
    static WIDTH = 'WIDTH';
    static HEIGHT = 'HEIGHT';
    static BARS = 'BARS';
    static INFO = 'INFO';
    static INFO1 = 'INFO1';
    static INFO2 = 'INFO2';
    static FULL_SCREEN = isCustomDesigns
        ? SvgIcon.FULL_SCREEN_CLASSIC
        : SvgIcon.FULL_SCREEN1;
    static FULL_SCREEN1 = 'FULL_SCREEN1';
    static FULL_SCREEN_CLASSIC1 = 'FULL_SCREEN_CLASSIC1';
    static FULL_SCREEN_CUSTOM2 = 'FULL_SCREEN_CUSTOM2';
    static FULL_SCREEN_CLASSIC = isCustom2Design
        ? SvgIcon.FULL_SCREEN_CUSTOM2
        : SvgIcon.FULL_SCREEN_CLASSIC1;
    static ALARM = 'ALARM';
    static EMPTY_DATA = 'EMPTY_DATA';
    static SUCCESS = 'SUCCESS';
    static ERROR = 'ERROR';
    static CARET_DOWN = "CARET_DOWN";
    static BRACKET_UP = isVersion6DesktopTarget ? SvgIcon.CARET_DOWN : 'BRACKET_UP';
    static BRACKET_UP1 = 'BRACKET_UP1';
    static VERSION6_SETTINGS = 'VERSION6_SETTINGS';
    static EDIT_CLASSIC = 'EDIT_CLASSIC';
    static VERSION6_EDIT = 'VERSION6_EDIT';
    static EDIT = isVersion6Design ? SvgIcon.VERSION6_EDIT : SvgIcon.EDIT_CLASSIC;
    static DOCUMENT = 'DOCUMENT';
    static DOCUMENT2 = 'DOCUMENT2';
    static LOAD1 = 'LOAD1';
    static LOAD2 = 'LOAD2';
    static LOAD = isClassicDesign ? SvgIcon.LOAD1 : SvgIcon.LOAD2;
    static RESET_CLASSIC1 = 'RESET_CLASSIC1';
    static RESET_CUSTOM2 = 'RESET_CUSTOM2';
    static RESET_CLASSIC = isCustom2Design
        ? SvgIcon.RESET_CUSTOM2
        : isVersion6Design 
        ? SvgIcon.RESET
        : SvgIcon.RESET_CLASSIC1;

    static EMAIL_LOCK = 'EMAIL_LOCK';
    static PASSWORD_INPUT = 'PASSWORD_INPUT';
    static TWO_FA = 'TWO_FA';
    static PROFILE_MENU = 'PROFILE_MENU';
    static KEY = 'KEY';
    static KEY2 = 'KEY2';

    static TAB_ACCOUNT_HISTORY = 'TAB_ACCOUNT_HISTORY';

    static ARROW_DOWN = 'ARROW_DOWN';
    static ARROW_UP = 'ARROW_UP';
    static ARROW_DOWN2 = 'ARROW_DOWN2';
    static ARROW_UP2 = 'ARROW_UP2';
    static ARROW_BUY = 'ARROW_BUY';
    static ARROW_SELL = 'ARROW_SELL';
    static LOCK = 'LOCK';
    static LOCK2 = 'LOCK2';
    static LOCK3 = 'LOCK3';
    static CHART = 'CHART';
    static CHECK_CIRCLE = 'CHECK_CIRCLE';
    static COPY = 'COPY';
    static COPY2 = 'COPY2';
    static COPY3 = 'COPY3';
    static COPY4 = 'COPY4';
    static VERSION6_COPY = 'VERSION6_COPY';
    static COPY_ICON3 = isCustom2Design
        ? SvgIcon.COPY4
        : isCustomDesigns
            ? SvgIcon.COPY2
            : isVersion6Design
                ? SvgIcon.VERSION6_COPY
                : SvgIcon.COPY3;
    static DEMO_ACCOUNT = 'DEMO_ACCOUNT';
    static DEPOSIT = 'DEPOSIT';
    static CALENDAR = 'CALENDAR';
    static CALENDAR_CLASSIC = 'CALENDAR_CLASSIC';
    static CALENDAR_CLASSIC2 = 'CALENDAR_CLASSIC2';
    static CALENDAR_CUSTOM2 = 'CALENDAR_CUSTOM2';
    static SUPPORT_MESSAGE = 'SUPPORT_MESSAGE';
    static SUPPORT_MESSAGE1 = 'SUPPORT_MESSAGE1';
    static LOG_OUT = 'LOG_OUT';
    static LOG_OUT1 = 'LOG_OUT1';
    static LOG_OUT_CLASSIC = 'LOG_OUT_CLASSIC';
    static DRIVER_LICENSE = 'DRIVER_LICENSE';
    static PASSPORT = 'PASSPORT';
    static OTHER_ID = 'OTHER_ID';
    static MONEYS = 'MONEYS';
    static QR1 = 'QR1';
    static QR2 = 'QR2';
    static QR3 = 'QR3';
    static QR = isCustom2Design
        ? SvgIcon.QR3
        : isCustomDesigns
        ? SvgIcon.QR2
        : SvgIcon.QR1;
    static SEARCH1 = 'SEARCH1';
    static SEARCH2 = 'SEARCH2';
    static SEARCH3 = 'SEARCH3';
    static SEARCH = isVersion6Design ? 'SEARCH3' : isCustom2Design ? 'SEARCH2' : 'SEARCH';
    static MICROPHONE = 'MICROPHONE';
    static SEND = 'SEND';
    static RECEIVED = 'RECEIVED';
    static MENU = 'MENU';
    static FILTERS_MENU = 'FILTERS_MENU';
    static TICK_CIRCLE = 'TICK_CIRCLE';
    static TAB_TRADE = 'TAB_TRADE';
    static TAB_TRADE_CUSTOM2 = 'TAB_TRADE_CUSTOM2';
    static TAB_ASSETS = 'TAB_ASSETS';
    static TAB_ASSETS_CUSTOM2 = 'TAB_ASSETS_CUSTOM2';
    static TAB_POSITIONS_NEW = 'TAB_POSITIONS_NEW';
    static TAB_ORDERS_NEW = 'TAB_ORDERS_NEW';
    static TAB_ORDERS_NEW_CUSTOM2 = 'TAB_ORDERS_NEW_CUSTOM2';
    static SAVE1 = 'SAVE1';
    static SAVE_CUSTOM2 = 'SAVE_CUSTOM2';
    static SAVE = isCustom2Design ? 'SAVE_CUSTOM2' : 'SAVE1';
    static SHIELD = 'SHIELD';
    static WITHDRAW = 'WITHDRAW';

    static MASTERCARD = 'MASTERCARD';
    static VISA = 'VISA';
    static VISA2 = 'VISA2';

    static CLOCK = 'CLOCK';
    static CLOCK22 = 'CLOCK22';
    static CLOCK3 = 'CLOCK3';
    static CLOCK4 = 'CLOCK4';
    static CLOCK2 = isCustom2Design ? 'CLOCK4' : 'CLOCK22';
    static CLOCK_CUSTOM2 = 'CLOCK_CUSTOM2';
    static TARGET = 'TARGET';
    static PERCENT = 'PERCENT';

    static BACK_ARROW = 'BACK_ARROW';

    static STRATEGY_STEP_0 = 'STRATEGY_STEP_0';
    static STRATEGY_STEP_1 = 'STRATEGY_STEP_1';
    static STRATEGY_STEP_2 = 'STRATEGY_STEP_2';
    static STRATEGY_STEP_3 = 'STRATEGY_STEP_3';

    static TWOFA_STEP_0 = 'TWOFA_STEP_0';
    static TWOFA_STEP_1 = 'TWOFA_STEP_1';
    static TWOFA_STEP_2 = 'TWOFA_STEP_2';
    static TWOFA_STEP_3 = 'TWOFA_STEP_3';
    static TWOFA_SUCCESS = 'TWOFA_SUCCESS';
    static TWOFA_FAIL = 'TWOFA_FAIL';

    static TWOFA_STEP_0_CLASSIC1 = 'TWOFA_STEP_0_CLASSIC1';
    static TWOFA_STEP_0_CUSTOM2 = 'TWOFA_STEP_0_CUSTOM2';
    static TWOFA_STEP_0_CLASSIC = isCustom2Design
        ? SvgIcon.TWOFA_STEP_0_CUSTOM2
        : SvgIcon.TWOFA_STEP_0_CLASSIC1;
    static TWOFA_STEP_1_CLASSIC = 'TWOFA_STEP_1_CLASSIC';
    static TWOFA_STEP_2_CLASSIC = 'TWOFA_STEP_2_CLASSIC';
    static TWOFA_STEP_3_CLASSIC = 'TWOFA_STEP_3_CLASSIC';
    static TWOFA_QR_CLASSIC = 'TWOFA_QR_CLASSIC';
    static TWOFA_SUCCESS_CLASSIC = 'TWOFA_SUCCESS_CLASSIC';

    static DOWNLOAD1 = 'DOWNLOAD1';
    static DOWNLOAD = isCustom2Design ? SvgIcon.TWOFA_STEP_0_CLASSIC : SvgIcon.DOWNLOAD1;

    static SHIELD_CLASSIC = 'SHIELD_CLASSIC';
    static SUPPORT_MESSAGE_CLASSIC = 'SUPPORT_MESSAGE_CLASSIC';
    static PROFILE_CIRCLE = 'PROFILE_CIRCLE';

    static I_2_FACTOR_AUTHENTICATION = 'I_2_FACTOR_AUTHENTICATION';
    static I_FAQ = 'I_FAQ';
    static I_ACCOUNT = 'I_ACCOUNT';
    static I_ACTIVITY_LOG = 'I_ACTIVITY_LOG';
    static I_ACTIVITY_LOG1 = 'I_ACTIVITY_LOG1';
    static I_ASSET_PRICE1 = 'I_ASSET_PRICE1';
    static I_ASSET_PRICE = isVersion6Design ? 'I_ASSET_PRICE2' :  'I_ASSET_PRICE1';
    static I_BUY1 = 'I_BUY1';
    static I_BUY2 = 'I_BUY2';
    static VERSION6_ARROW_UP = 'VERSION6_ARROW_UP';
    static VERSION6_ARROW_DOWN = 'VERSION6_ARROW_DOWN';
    static TRADE_BUTTON_ARROW = 'TRADE_BUTTON_ARROW'
    static I_BUY = isCustom2Design ? 'I_BUY2' : isVersion6DesktopTarget ? SvgIcon.VERSION6_ARROW_UP : 'I_BUY1';
    static I_CALL = 'I_CALL';
    static I_GLOBE = 'I_GLOBE';
    static I_GLOBE1 = 'I_GLOBE1';
    static I_NEWS = 'I_NEWS';
    static I_NEWS1 = 'I_NEWS1';

    static I_CLOSE = 'I_CLOSE';
    static I_DEPOSIT1 = 'I_DEPOSIT1';
    static I_DEPOSIT2 = 'I_DEPOSIT2';
    static I_DEPOSIT3 = 'I_DEPOSIT3';
    static I_DEPOSIT = isVersion6Design ? 'I_DEPOSIT3' : isCustom2Design ? 'I_DEPOSIT2' : 'I_DEPOSIT1';
    static I_DONE = 'I_DONE';
    static MESSAGE_SUCCESS1 = 'MESSAGE_SUCCESS1';
    static MESSAGE_SUCCESS = isVersion6DesktopTarget ? 'MESSAGE_SUCCESS1' : 'I_DONE';
    static MESSAGE_UNSUCCESS = 'MESSAGE_UNSUCCESS';
    static I_DROP_DOWN = isVersion6DesktopTarget ? 'BRACKET_UP1' : 'I_DROP_DOWN';
    static I_DROP_DOWN_CLASSIC = 'I_DROP_DOWN_CLASSIC';
    static I_DROP_DOWN_CUSTOM2 = 'I_DROP_DOWN_CUSTOM2';
    static I_DROP_DOWN_ROUNDED = 'I_DROP_DOWN_ROUNDED';
    static I_DROP_UP = 'I_DROP_UP';
    static I_DROP_UP_ROUNDED = 'I_DROP_UP_ROUNDED';
    static I_EMAIL1 = 'I_EMAIL1';
    static I_EMAIL2 = 'I_EMAIL2';
    static I_EMAIL = isCustom2Design ? 'I_EMAIL2' : 'I_EMAIL1';
    static I_FILTER = 'I_FILTER';
    static I_GENERAL_SETTINGS = 'I_GENERAL_SETTINGS';
    static I_GENERAL_SETTINGS1 = 'I_GENERAL_SETTINGS1';
    static I_TRADE_SETTINGS = 'I_TRADE_SETTINGS';
    static I_SECURITY = 'I_SECURITY';
    static I_EDUCATION = 'I_EDUCATION';
    static I_PHONE = 'I_PHONE';
    static I_HISTORY = isVersion6DesktopTarget ? SvgIcon.CLOCK : 'I_HISTORY';
    static I_HISTORY2 = 'I_HISTORY2';
    static I_HISTORY3 = 'I_HISTORY3';
    static I_INDICATORS = 'I_INDICATORS';
    static I_EXCHANGE = 'I_EXCHANGE';
    static I_INDICATORS22 = 'I_INDICATORS22';
    static I_INDICATORS_CLASSIC = 'I_INDICATORS_CLASSIC';
    static I_INDICATORS_CUSTOM2 = 'I_INDICATORS_CUSTOM2';
    static I_INDICATORS2 = isCustom2Design
        ? SvgIcon.I_INDICATORS_CUSTOM2
        : SvgIcon.I_INDICATORS22;
    static I_DRAW = 'I_DRAW';
    static I_INVESTING = 'I_INVESTING';
    static I_INVESTING_CUSTOM2 = 'I_INVESTING_CUSTOM2';
    static I_LIFE_SUPPORT = 'I_LIFE_SUPPORT';
    static I_MINING = 'I_MINING';
    static I_MONEY1 = 'I_MONEY1';
    static I_MONEY2 = 'I_MONEY2';
    static I_MONEY = isVersion6Design ? 'I_MONEY2' : "I_MONEY1";
    static I_NOTIFICATION_MAIN = 'I_NOTIFICATION_MAIN';
    static I_NOTIFICATION_CUSTOM = 'I_NOTIFICATION_CUSTOM';
    static I_NOTIFICATION_CUSTOM_NEW = 'I_NOTIFICATION_CUSTOM_NEW';
    static I_NOTIFICATION_CONFIRMATION = isCustomDesigns
        ? SvgIcon.I_NOTIFICATION_CUSTOM
        : SvgIcon.I_NOTIFICATION_MAIN;
    static I_NOTIFICATION_CONFIRMATION_NEW = isCustomDesigns
        ? SvgIcon.I_NOTIFICATION_CUSTOM_NEW
        : SvgIcon.I_NOTIFICATION_MAIN;
    static NOTIFICATIONS = 'NOTIFICATIONS';
    static NOTIFICATIONS_NEW = 'NOTIFICATIONS_NEW';
    static I_NOTIFICATIONS_SETTINGS = 'I_NOTIFICATIONS_SETTINGS';
    static I_ORDER = 'I_ORDER';
    static I_PASSWORD = 'I_PASSWORD';
    static VERSION6_DESKTOP_PASSWORD_VISIBLE = 'VERSION6_DESKTOP_PASSWORD_VISIBLE';
    static VERSION6_DESKTOP_PASSWORD_INVISIBLE = 'VERSION6_DESKTOP_PASSWORD_INVISIBLE';
    static I_PASSWORD_INVISIBLE1 = 'I_PASSWORD_INVISIBLE1';
    static I_PASSWORD_INVISIBLE2 = 'I_PASSWORD_INVISIBLE2';
    static PASSWORD_INVISIBLE = 'PASSWORD_INVISIBLE';
    static I_CUSTOM2_PASSWORD_INVISIBLE = 'I_CUSTOM2_PASSWORD_INVISIBLE';
    static I_PASSWORD_VISIBLE1 = 'I_PASSWORD_VISIBLE1';
    static I_PASSWORD_VISIBLE2 = 'I_PASSWORD_VISIBLE2';
    static PASSWORD_VISIBLE = 'PASSWORD_VISIBLE';
    static I_CUSTOM2_PASSWORD_VISIBLE = 'I_CUSTOM2_PASSWORD_VISIBLE';
    static TOOLS_PANEL_VISIBLE = isClassicDesign
        ? 'PASSWORD_VISIBLE'
        : isCustom2Design
        ? 'I_CUSTOM2_PASSWORD_VISIBLE'
        : isVersion6Design
        ? 'VERSION6_VISIBLE'
        : 'I_PASSWORD_VISIBLE1';
    static I_PASSWORD_INVISIBLE = isClassicDesign
        ? 'PASSWORD_INVISIBLE'
        : isCustom2Design
        ? 'I_CUSTOM2_PASSWORD_INVISIBLE'
        : isVersion6MobileTarget
        ? 'I_PASSWORD_INVISIBLE2'
        : isVersion6DesktopTarget
        ? 'VERSION6_DESKTOP_PASSWORD_INVISIBLE'
        : 'I_PASSWORD_INVISIBLE1';
    static INDICATOR_HIDDEN = isVersion6Design
    ? SvgIcon.VERSION6_DESKTOP_PASSWORD_INVISIBLE
    : SvgIcon.I_CUSTOM2_PASSWORD_INVISIBLE;
    static I_PASSWORD_VISIBLE = isClassicDesign
        ? 'PASSWORD_VISIBLE'
        : isCustom2Design
        ? 'I_CUSTOM2_PASSWORD_VISIBLE'
        : isVersion6MobileTarget
        ? 'I_PASSWORD_VISIBLE2'
        : isVersion6DesktopTarget
        ? 'VERSION6_DESKTOP_PASSWORD_VISIBLE'
        : 'I_PASSWORD_VISIBLE1';
    static I_PERCENTAGE1 = 'I_PERCENTAGE1';
    static I_PERCENTAG2E = 'I_PERCENTAGE2';
    static I_PERCENTAGE = isVersion6Design ? 'I_PERCENTAGE2' : 'I_PERCENTAGE1';
    static SLTP_PERCENT = 'SLTP_PERCENT';
    static SLTP_SIZE = 'SLTP_SIZE';
    static SLTP_GAVEL = 'SLTP_GAVEL';
    static I_PERSONAL_DATA = 'I_PERSONAL_DATA';
    static I_PERSONAL_DATA1 = 'I_PERSONAL_DATA1';
    static I_PERSONAL_DATA2 = 'I_PERSONAL_DATA2';
    static I_SUCCESS_FILLED = isVersion6DesktopTarget ? 'MESSAGE_SUCCESS1' : 'I_SUCCESS_FILLED';
    static I_REQUIRED_FILLED = isVersion6DesktopTarget ? 'INFO2'
    : isVersion6Design
    ? 'CLOSE' 
    : isCustom2Design 
    ? 'WARNING1' 
    : 'I_REQUIRED_FILLED';
    static I_POSITION = 'I_POSITION';
    static I_REPORTS = 'I_REPORTS';
    static I_RIGHT = 'I_RIGHT';
    static I_SELL1 = 'I_SELL1';
    static I_SELL2 = 'I_SELL2';
    static I_SELL = isCustom2Design ? 'I_SELL2' : isVersion6DesktopTarget ? SvgIcon.VERSION6_ARROW_DOWN : 'I_SELL1';
    static I_SERVICES = 'I_SERVICES';
    static I_TEACHER = 'I_TEACHER';
    static I_TEACHER1 = 'I_TEACHER1';
    static I_TUNE = 'I_TUNE';
    static I_STUDYING = 'I_STUDYING';
    static I_SUPPORT = 'I_SUPPORT';
    static I_TRENDING = 'I_TRENDING';
    static I_WALLET = 'I_WALLET';
    static I_WITHDRAW1 = 'I_WITHDRAW1';
    static I_WITHDRAW2 = 'I_WITHDRAW2';
    static I_WITHDRAW3 = 'I_WITHDRAW3';
    static I_WITHDRAW = isVersion6Design ? 'I_WITHDRAW3' : isCustom2Design ? 'I_WITHDRAW2' : 'I_WITHDRAW1';
    static FLAG_DE = 'FLAG_DE';
    static FLAG_EN = 'FLAG_EN';
    static FLAG_ES = 'FLAG_ES';
    static FLAG_FR = 'FLAG_FR';
    static FLAG_PT = 'FLAG_PT';
    static FLAG_PL = 'FLAG_PL';
    static FLAG_RU = 'FLAG_RU';
    static FLAG_ZH = 'FLAG_ZH';
    static FLAG_CN = 'FLAG_CN';
    static FLAG_TR = 'FLAG_TR';
    static FLAG_HI = 'FLAG_HI';
    static WARNING = 'WARNING';
    static WARNING1 = 'WARNING1';
    static WARNING2 = 'WARNING2';
    static WARNING3 = 'WARNING3';
    static ADV_CASH = 'ADV_CASH';
    static ALFA_CASH = 'ALFA_CASH';
    static QIWI = 'QIWI';
    static VISA_MK = 'VISA_MK';
    static WME = 'WME';
    static YANDEX_MONEY = 'YANDEX_MONEY';
    static WIRE_TRANSFER = 'WIRE_TRANSFER';
    static PROFILE = 'PROFILE';
    static PICTURE = 'PICTURE';
    static FIRE = 'FIRE';
    static GOOGLE_PAY = 'GOOGLE_PAY';
    static APPLE_PAY = 'APPLE_PAY';
    static PAYEER = 'PAYEER';
    static BESTCHANGE = 'BESTCHANGE';
    static MIR = 'MIR';
    static WEBMONEY = 'WEBMONEY';
    static YANDEX_MONEY_FAKE = 'YANDEX_MONEY_FAKE';
    static VISA_FAKE = 'VISA_FAKE';
    static WITHDRAW_WALLET = 'WITHDRAW_WALLET';
    static EMPTY_WALLET = 'EMPTY_WALLET';
    static REFERRAL = 'REFERRAL';
    static ORDER_BOOK1 = 'ORDER_BOOK1';
    static ORDER_BOOK2 = 'ORDER_BOOK2';
    static ORDER_BOOK = isVersion6Design ? 'ORDER_BOOK2' : 'ORDER_BOOK1';

    // DRAW OBJECTS
    static I_BRUSH1 = 'I_BRUSH1';
    static I_BRUSH2 = 'I_BRUSH2';
    static I_BRUSH3 = 'I_BRUSH3';
    static I_BRUSH_CLASSIC = 'I_BRUSH_CLASSIC';
    static I_BRUSH = isVersion6MobileTarget ? 'VERSION6_BRUSH' : isClassicDesign
        ? 'I_BRUSH_CLASSIC'
        : isCustom2Design
        ? 'I_BRUSH2'
        : isVersion6DesktopTarget
        ? 'I_BRUSH3'
        : 'I_BRUSH1';
    static I_FIBNACCI_FIBNACCI_EXPANSION = 'I_FIBNACCI_FIBNACCI_EXPANSION';
    static I_FIBNACCI_PITCHFORK = 'I_FIBNACCI_PITCHFORK';
    static I_FIBONACCI_FAN_GANN = 'I_FIBONACCI_FAN_GANN';
    static I_FIBONACCI_FIBONACCI_CHANNELS = 'I_FIBONACCI_FIBONACCI_CHANNELS';
    static I_FIBONACCI_FIBONACCI_RETRACEMENT =
        'I_FIBONACCI_FIBONACCI_RETRACEMENT';
    static I_FIBONACCI_FIBONACCI_TIME_PERIODS =
        'I_FIBONACCI_FIBONACCI_TIME_PERIODS';
    static I_FIBONACCI_PARALLEL_CHANNELS = 'I_FIBONACCI_PARALLEL_CHANNELS';
    static I_FIBONACCI_REGRESSION_TREND = 'I_FIBONACCI_REGRESSION_TREND';
    static I_FIBONACCI = 'I_FIBONACCI';
    static I_LINES_ARROW = 'I_LINES_ARROW';
    static I_LINES_CURVE = 'I_LINES_CURVE';
    static I_LINES_HORIZONTAL_LINE = 'I_LINES_HORIZONTAL_LINE';
    static I_LINES_HORIZONTAL_RAY = 'I_LINES_HORIZONTAL_RAY';
    static I_LINES_RAY = 'I_LINES_RAY';
    static I_LINES_STRAIGHT = 'I_LINES_STRAIGHT';
    static I_LINES_TREND_ANGLE = 'I_LINES_TREND_ANGLE';
    static I_LINES_TREND_LINE = 'I_LINES_TREND_LINE';
    static I_LINES_VERTICAL_LINE = 'I_LINES_VERTICAL_LINE';
    static I_LINES = 'I_LINES';
    static I_NOTES_ARROW_DOWN = 'I_NOTES_ARROW_DOWN';
    static I_NOTES_ARROW_UP = 'I_NOTES_ARROW_UP';
    static I_NOTES_FLAG = 'I_NOTES_FLAG';
    static I_NOTES_TEXT = 'I_NOTES_TEXT';
    static I_NOTES = 'I_NOTES';
    static I_SHAPES_RECTANGLE = 'I_SHAPES_RECTANGLE';
    static I_SHAPES_ROTATE_ELLIPSE = 'I_SHAPES_ROTATE_ELLIPSE';
    static I_SHAPES_ROTATE_RECTANGLE = 'I_SHAPES_ROTATE_RECTANGLE';
    static I_SHAPES_ROTATE_TRIANGLE = 'I_SHAPES_ROTATE_TRIANGLE';
    static I_SHAPES = 'I_SHAPES';
    static AREA_CHART_C = 'AREA_CHART_C';
    static LINE_CHART_C = 'LINE_CHART_C';
    static CANDLES_CHART_C = 'CANDLES_CHART_C';
    static HAIKIN_ASHI_CHART_C = 'HAIKIN_ASHI_CHART_C';
    static OHLC_CHART_C = 'OHLC_CHART_C';

    static CHART_COLOR_BW = 'CHART_COLOR_BW';
    static CHART_COLOR_HA = 'CHART_COLOR_HA';
    static CHART_COLOR_AREA_COLOR = 'CHART_COLOR_AREA_COLOR';
    static CHART_COLOR = 'CHART_COLOR';

    static CHECK_OFF1 = 'CHECK_OFF1';
    static CHECK_ON1 = 'CHECK_ON1';
    static CHECK_OFF2 = 'CHECK_OFF2';
    static CHECK_ON2 = 'CHECK_ON2';
    static RADIO_OFF = 'RADIO_OFF';
    static RADIO_ON = 'RADIO_ON';

    static CHART_POS_ARROW_UP = 'CHART_POS_ARROW_UP';
    static CHART_POS_ARROW_DOWN = 'CHART_POS_ARROW_DOWN';
    static DRAG_INDICATOR = 'DRAG_INDICATOR';

    // INDICATORS

    // BILL_WILLIAMS_GROUP
    static I_INDICATOR_BW_GROUP = 'I_INDICATOR_BW_GROUP';
    static I_INDICATOR_AC = 'I_INDICATOR_AC';
    static I_INDICATOR_BWMFI = 'I_INDICATOR_BWMFI';
    static I_INDICATOR_WPR = 'I_INDICATOR_WPR';

    // MOVING_AVERAGE_GROUP
    static I_INDICATOR_MA_GROUP = 'I_INDICATOR_MA_GROUP';
    static I_INDICATOR_DEMA = 'I_INDICATOR_DEMA';
    static I_INDICATOR_EMA = 'I_INDICATOR_EMA';
    static I_INDICATOR_FRAMA = 'I_INDICATOR_FRAMA';
    static I_INDICATOR_HMA = 'I_INDICATOR_HMA';
    static I_INDICATOR_KAMA = 'I_INDICATOR_KAMA';
    static I_INDICATOR_SMA = 'I_INDICATOR_SMA';
    static I_INDICATOR_SMMA = 'I_INDICATOR_SMMA';
    static I_INDICATOR_TMA = 'I_INDICATOR_TMA';
    static I_INDICATOR_VMA = 'I_INDICATOR_VMA';
    static I_INDICATOR_WMA = 'I_INDICATOR_WMA';

    // OSCILLATOR_GROUP
    static I_INDICATOR_OSCILLATOR_GROUP = 'I_INDICATOR_OSCILLATOR_GROUP';
    static I_INDICATOR_BEARPOW = 'I_INDICATOR_BEARPOW';
    static I_INDICATOR_BULLPOW = 'I_INDICATOR_BULLPOW';
    static I_INDICATOR_CCI = 'I_INDICATOR_CCI';
    static I_INDICATOR_CHO = 'I_INDICATOR_CHO'
    static I_INDICATOR_DEM = 'I_INDICATOR_DEM';
    static I_INDICATOR_FRC = 'I_INDICATOR_FRC';
    static I_INDICATOR_MACD = 'I_INDICATOR_MACD';
    static I_INDICATOR_MOM = 'I_INDICATOR_MOM';
    static I_INDICATOR_RSI = 'I_INDICATOR_RSI';
    static I_INDICATOR_RVI = 'I_INDICATOR_RVI';
    static I_INDICATOR_STO = 'I_INDICATOR_STO';

    // TREND_AND_CHANEL_GROUP
    static I_INDICATOR_TRADE_AND_CHANEL_GROUP = 'I_INDICATOR_TRADE_AND_CHANEL_GROUP';
    static I_INDICATOR_ADX = 'I_INDICATOR_ADX';
    static I_INDICATOR_BB = 'I_INDICATOR_BB';
    static I_INDICATOR_IC = 'I_INDICATOR_IC';
    static I_INDICATOR_SAR = 'I_INDICATOR_SAR';
    static I_INDICATOR_VIDYA = 'I_INDICATOR_VIDYA';
    static I_INDICATOR_ENVELOPES = 'I_INDICATOR_ENVELOPES';
    static I_INDICATOR_TEMA = 'I_INDICATOR_TEMA';
    static I_INDICATOR_STANDARD_DEVIATION = 'I_INDICATOR_STANDARD_DEVIATION';
    static I_INDICATOR_ADMIW = 'I_INDICATOR_ADMIW';

    // VOLATILITY_GROUP
    static I_INDICATOR_VOLATILITY_GROUP = 'I_INDICATOR_VOLATILITY_GROUP';
    static I_INDICATOR_ATR = 'I_INDICATOR_ATR';

    // VOLUMES_GROUP
    static I_INDICATOR_VOLUMES_GROUP = 'I_INDICATOR_VOLUMES_GROUP';
    static I_INDICATOR_A_D = 'I_INDICATOR_A_D';
    static I_INDICATOR_MFI = 'I_INDICATOR_MFI';
    static I_INDICATOR_OBV = 'I_INDICATOR_OBV';

    static I_BRACKET_RIGHT = 'I_BRACKET_RIGHT';
    static I_BRACKET_LEFT = 'I_BRACKET_LEFT';

    static I_DATE = 'I_DATE';
    static I_SYNC = 'I_SYNC';
    static I_CUP = 'I_CUP';
    static I_COINS = 'I_COINS';
    static I_COINS1 = 'I_COINS1';

    // AUTH SOCIAL
    static I_FACEBOOK = 'I_FACEBOOK';
    static I_GOOGLE = 'I_GOOGLE';

    static TWO_OPPOSITE_ARROWS = 'TWO_OPPOSITE_ARROWS';
    static TRANSFER_MONEY = 'TRANSFER_MONEY';

    // custom2 C2_LOGOUT_MODAL
    static C2_SETTINGS = 'C2_SETTINGS';
    static C2_LOGOUT = 'C2_LOGOUT';
    static C2_USER_LOGO = 'C2_USER_LOGO';
    static C2_OK = 'C2_OK';
    static C2_WALLET = 'C2_WALLET';
    static C2_REQUEST_CALLBACK = 'C2_REQUEST_CALLBACK';
    static C2_ACCESS_KEYS = 'C2_ACCESS_KEYS';

    static SUN = "SUN";
    static MOON = "MOON";

    static VERSION6_WHAT_TO_BUY = "VERSION6_WHAT_TO_BUY";
    static VERSION6_FAVORITES = "VERSION6_FAVORITES";
    static VERSION6_PORTFOLIO = "VERSION6_PORTFOLIO";
    static VERSION6_SUPPORT = "VERSION6_SUPPORT";
    static VERSION6_MENU = "VERSION6_MENU";
    static VERSION6_CHECKED = 'VERSION6_CHECKED';
    static VERSION6_CALENDAR = 'VERSION6_CALENDAR';
    static VERSION6_CALENDAR2 = 'VERSION6_CALENDAR2';
    static VERSION6_CALENDAR3 = 'VERSION6_CALENDAR3';
    static VERSION6_DOWNLOAD = 'VERSION6_DOWNLOAD';
    static VERSION6_DOWNLOAD2 = 'VERSION6_DOWNLOAD2';
    static VERSION6_FILTER = 'VERSION6_FILTER';
    static VERSION6_GALLERY = 'VERSION6_GALLERY';
    static VERSION6_STAR_SLASH = 'VERSION6_STAR_SLASH';
    static VERSION6_STAR = 'VERSION6_STAR';
    static VERSION6_STAR_ADD = 'VERSION6_STAR_ADD';
    static VERSION6_BRUSH = 'VERSION6_BRUSH';
    static VERSION6_FULLSCREEN = 'VERSION6_FULLSCREEN';
    static INDICATORS_ICON = 'INDICATORS_ICON';
    static VERSION6_INDICATORS = isVersion6DesktopTarget ? 'INDICATORS_ICON' : 'VERSION6_INDICATORS';
    static VERSION6_NOTIFICATION = 'VERSION6_NOTIFICATION';
    static VERSION6_DIAGONAL = 'VERSION6_DIAGONAL';
    static VERSION6_VWAP = 'VERSION6_VWAP';
    static VERSION6_VERTICAL = 'VERSION6_VERTICAL';
    static VERSION6_DEPOSIT = 'VERSION6_DEPOSIT';
    static VERSION6_WITHDRAWAL = 'VERSION6_WITHDRAWAL';
    static VERSION6_BUY = 'VERSION6_BUY';
    static VERSION6_SELL = 'VERSION6_SELL';
    static BUY_BUTTON_ARROW = isVersion6DesktopTarget ? "TRADE_BUTTON_ARROW" : 'VERSION6_BUY' ;
    static SELL_BUTTON_ARROW = isVersion6DesktopTarget ? "TRADE_BUTTON_ARROW" : 'VERSION6_SELL' ;
    static VERSION6_DIAGRAM = 'VERSION6_DIAGRAM';
    static VERSION6_WAIT = 'VERSION6_WAIT';

    static I_SETTINGS1 = 'I_SETTINGS1';
    static I_SETTINGS2 = 'I_SETTINGS2';
    static I_SETTINGS3 = 'I_SETTINGS3';
    static VERSION6_SETTINGS2 = 'VERSION6_SETTINGS2';
    static TOOLS_PANEL_SETTINGS = isCustom2Design
        ? 'I_SETTINGS2'
        : isVersion6Design
            ? 'VERSION6_SETTINGS2'
            : 'I_SETTINGS1';
    static I_SETTINGS = isCustom2Design 
        ? 'I_SETTINGS2'
        : isVersion6Design 
            ? 'VERSION6_SETTINGS' 
            : 'I_SETTINGS1';
    static VERSION6_CHART_SETTINGS = isVersion6DesktopTarget ? SvgIcon.VERSION6_SETTINGS2 :'VERSION6_CHART_SETTINGS';
    static VERSION6_TRASH = 'VERSION6_TRASH';
    static TRASH1 = 'TRASH1';
    static TRASH2 = 'TRASH2';
    static TRASH3 = 'TRASH3';
    static TRASH_CLASSIC = 'TRASH_CLASSIC';
    static TRASH = isVersion6Design ? SvgIcon.VERSION6_TRASH : isClassicDesign
        ? SvgIcon.TRASH_CLASSIC
        : isCustom2Design
            ? SvgIcon.TRASH2
            : SvgIcon.TRASH1;

    static VERSION6_DIAGRAM = 'VERSION6_DIAGRAM';
    static VERSION6_SEARCH = 'VERSION6_SEARCH';
    static SEARCH_LIST = 'SEARCH_LIST';
    static VERSION6_PROFILE = 'VERSION6_PROFILE';
    static VERSION6_SOUND = 'VERSION6_SOUND';
    static VERSION6_RELOAD = 'VERSION6_RELOAD';
    static VERSION6_REJECTED = 'VERSION6_REJECTED';
    static RELOAD1 = 'RELOAD1';
    static RELOAD_CLASSIC = 'RELOAD_CLASSIC';
    static RELOAD = isVersion6Design ? SvgIcon.VERSION6_RELOAD : isClassicDesign ? SvgIcon.RELOAD_CLASSIC : SvgIcon.RELOAD1;
    static CHART_BUY1 = "CHART_BUY1";
    static CHART_SELL1 = "CHART_SELL1";
    static CHART_BUY2 = "CHART_BUY2";
    static CHART_SELL2 = "CHART_SELL2";
    static CHART_BUY3 = "CHART_BUY3";
    static CHART_SELL3 = "CHART_SELL3";
    static CHART_BUY = isVersion6DesktopTarget ? "CHART_BUY2" : "CHART_BUY1";
    static CHART_SELL = isVersion6DesktopTarget ? "CHART_SELL2" : "CHART_SELL1";
    static I_GALLERY = 'I_GALLERY';
    static CHECK_OFF = isVersion6Design ? null : isCustomDesigns ? 'CHECK_OFF2' : 'CHECK_OFF1';
    static CHECK_ON = isVersion6Design ? 'VERSION6_CHECKED' : isCustomDesigns ? 'CHECK_ON2' : 'CHECK_ON1';
    static GALLERY = isVersion6Design ? SvgIcon.VERSION6_GALLERY : SvgIcon.I_GALLERY;
    static SPINNER_CIRCLE = "SPINNER_CIRCLE";

    static I_ADDED_COMMON = 'I_ADDED';
    static VERSION6_I_ADDED = 'VERSION6_I_ADDED';
    static I_ADDED = isVersion6Design ? SvgIcon.VERSION6_I_ADDED : SvgIcon.I_ADDED_COMMON;
    static I_FAVORITES_COMMON = 'I_FAVORITES';
    static I_FAVORITES = isVersion6Design ? SvgIcon.VERSION6_STAR : SvgIcon.I_FAVORITES_COMMON;
    static I_POPULAR_COMMON = 'I_POPULAR';
    static VERSION6_I_POPULAR = 'VERSION6_I_POPULAR';
    static I_POPULAR = isVersion6Design ? SvgIcon.VERSION6_I_POPULAR : SvgIcon.I_POPULAR_COMMON;
    static I_TEMPLATES_COMMON = 'I_TEMPLATES';
    static VERSION6_I_TEMPLATES = 'VERSION6_I_TEMPLATES';
    static I_TEMPLATES = isVersion6Design ? SvgIcon.VERSION6_I_TEMPLATES : SvgIcon.I_TEMPLATES_COMMON;
    static VERSION6_EMAIL = 'VERSION6_EMAIL';
    static REQUEST_CALLBACK = 'REQUEST_CALLBACK';

    static I_STAR1 = 'I_STAR1';
    static I_STAR2 = 'I_STAR2';
    static I_STAR = isVersion6Design ? SvgIcon.VERSION6_STAR : isCustom2Design ? 'I_STAR2' : 'I_STAR1';

    static GOOGLE_AUTHENTICATOR = 'GOOGLE_AUTHENTICATOR';
    static EMAIL_AUTHENTICATOR = 'EMAIL_AUTHENTICATOR';
    static SBP = 'SBP';
    static MESSAGE_ERROR1 = "MESSAGE_ERROR1" 
    static MESSAGE_ERROR = isVersion6Design ? 'MESSAGE_ERROR1' : 'CLOSE';
    static BRIEFCASE = "BRIEFCASE";
    static NEWS = "NEWS";
    static ECONOMIC_CALENDAR = "ECONOMIC_CALENDAR";
    static LOGOUT = "LOGOUT";
    static ADD = "ADD";
    static LIST_ITEM_ARROW = "LIST_ITEM_ARROW";
    static CONTACTS = "CONTACTS";
    static ADDRESS = "ADDRESS";
    static FILE_DOWNLOAD = "FILE_DOWNLOAD";
    static PANEL_SETTINGS = "PANEL_SETTINGS";
    static NOTEBOOK = "NOTEBOOK";

    render() {
        const {
            x,
            y,
            width,
            height,
            tagName,
            tagClassName,
            className,
            path,
            icon,
            fill,
            viewBox
        } = this.props;

        const imagePath = icon ? `${path}/${icon.toLowerCase()}.svg` : '';

        return (
            <SpriteSvg
                tagClassName={classNames('SvgIcon', tagClassName)}
                tagName={tagName}
                className={classNames('SvgIcon__svg', className)}
                srcClassName={className}
                src={imagePath}
                defaultIcon={null}
                viewBox={viewBox}
                svgProps={{ x, y, width, height, fill }}
            />
        );
    }
}

SvgIcon.defaultProps = {
    path: 'images/icons',
    tagName: 'i',
    tagClassName: '',
    className: '',
    fill: '',
    icon: '',
    x: 0,
    y: 0,
};

SvgIcon.propTypes = {
    path: PropTypes.string,
    noWrap: PropTypes.bool,
    tagName: PropTypes.string,
    tagClassName: PropTypes.string,
    className: PropTypes.string,
    fill: PropTypes.string,
    icon: PropTypes.string,
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    viewBox: PropTypes.string,
};

export default SvgIcon;
