import moment from 'moment';
import { AppConfig } from '../AppConfig';
import { isCustomDesigns } from '../utils/platform';

const requestInit = {
    requestParams: [],
    isRequestActive: false,
    timoutId: null
};

class TmxDataFeed {
    static SHOW_ASK = 'ASK';
    static SHOW_BID = 'BID';
    static MODE_SECOND = 'S';
    static MODE_TICK = 'T';

    _showValue = TmxDataFeed.SHOW_BID;
    get showValue() {
        return this._showValue;
    }

    set showValue(value) {
        const availableValues = [TmxDataFeed.SHOW_ASK, TmxDataFeed.SHOW_BID];
        if (availableValues.indexOf(value) === -1) {
            throw new Error(`Not valid set value for showValue, available: [${availableValues.split(',')}] current: ${value}`);
        }

        this._showValue = value;
    }

    _symbols = {};
    get symbols() {
        return this._symbols;
    }

    historyRequestTimeout = AppConfig.stockChart.historyRequestTimeout;

    _historyCallbacks = {};
    _lastSymbolsBars = {};
    _chartSubscribes = {};
    _historyGotBarsLastRequest = {}; // key as HistoryGotBarsLastRequest.getKey(), value as object instance of HistoryGotBarsLastRequest
    _requestQueue = {};

    config = {
        exchanges: [],
        symbols_types: [],
        supported_resolutions: ['1', '5', '15', '30', '60', '240', 'D', 'W', 'M'],
        supports_marks: false,
        supports_timescale_marks: false,
        supports_time: false
    };

    onReady(callback) {
        // chart feature
        window.setTimeout(() => {
            callback(this.config);
        }, 0);
    };

    // override events
    onGetBars = ({reqId, symbolId, periodM, periodB, from, to}) => {
    };

    addSymbol(symbol) {
        this._symbols[symbol.symbol] = symbol;
    }

    removeSymbol(symbol) {
        if (this._symbols[symbol.symbol]) {
            delete this._symbols[symbol.symbol];
        }
    }

    clearSymbols() {
        this._symbols = {};
    }

    static mapunits = (AppConfig.chartTradingView.active && isCustomDesigns) ? {
        "T": 0,
        "S": 10,
        "10S": 10,
        "20S": 20,
        "30S": 30,
        "1": 60,
        "5": 60 * 5,
        "15": 60 * 15,
        "30": 60 * 30,
        "60": 60 * 60,
        "240": 4 * 60 * 60,
        "D": 60 * 60 * 24,
        "W": 60 * 60 * 24 * 7,
        "M": -1
    } : {
        "20FS": 25,
        "30FS": 30,
        "1F": 60,
        "3F": 3 * 60,
        "5F": 5 * 60,
        "15F": 15 * 60,
        "30F": 30 * 60,
        "60F": 1 * 60 * 60,
        "180F": 3 * 60 * 60,
        "240F": 4 * 60 * 60,
        "720F": 12 * 60 * 60,
        "DF": 60 * 60 * 24,
        "WF": 60 * 60 * 24 * 7,
        "MF": -1,

        "30": 30,
        "60": 60,
        "180": 3 * 60,
        "360": 5 * 60,
        "720": 15 * 60,
        "D": 30 * 60,
        "W": 60 * 60 * 3,
        "M": 30 * 60 * 24,
        "3M": 30 * 60 * 24 * 2,
        "6M": 60 * 60 * 24 * 7,
        "1Y": 60 * 60 * 24 * 7,
        "3Y": -1,
    };

    static masks = {
        "T": function (time, reverse) {
            return reverse ? Math.floor(time * 1000000) : Math.floor(time / 1000000);
        },
        "1": function (time, reverse) {
            return reverse ? Math.floor(time * 60000) : Math.floor(time / 60000);
        },
        "5": function (time, reverse) {
            return reverse ? Math.floor(time * 60000) : Math.floor(time / 60000);
        },
        "0": function (time, reverse) {
            return reverse ? Math.floor(time * 60000) : Math.floor(time / 60000);
        },
        "S": function (time, reverse) {
            return reverse ? Math.floor(time * 1000000) : Math.floor(time / 1000000);
        },
        "10S": function (time, reverse) {
            return reverse ? Math.floor(time * 10000000) : Math.floor(time / 10000000);
        },
        "20S": function (time, reverse) {
            return reverse ? Math.floor(time * 20000000) : Math.floor(time / 20000000);
        },
        "30S": function (time, reverse) {
            return reverse ? Math.floor(time * 30000000) : Math.floor(time / 30000000);
        },
        "H": function (time, reverse) {
            return reverse ? Math.floor(time * 3600000) : Math.floor(time / 3600000);
        },
        "D": function (time, reverse) {
            return reverse ? Math.floor(time * 86400000) : Math.floor(time / 86400000);
        },
        "W": function (time, reverse) {
            return reverse ? Math.floor(time * 604800000) : Math.floor(time / 604800000);
        },
        "M": function (time, reverse) {
            return reverse ? (new Date()).setMonth(time) : new Date(time).getMonth();
        }
    };

    resolveSymbol = (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
        //surprise symbolName can be symbol ticker unique name :(
        const symbolId = symbolName.split("~")[0]; //separator from this unique key

        window.setTimeout(() => {
            if (this._symbols[symbolId]) {
                const uniqueKey = Date.now() + Math.floor(Math.random() * 1000000000);
                const symbol = {...this._symbols[symbolId]};
                //WARNING!!! generate unique ticker name for supports multiple charts
                symbol.ticker = `${symbol.ticker}~${uniqueKey}`;

                onSymbolResolvedCallback(symbol);
            } else {
                onResolveErrorCallback("not implemented");
            }
        });
    };

    searchSymbolsByName = (userInput, exchange, symbolType, onResultReadyCallback) => {
        const list = [];
        const searchValue = userInput.toLowerCase();

        Object.keys(this.symbols).forEach((key) => {
            const symbol = this.symbols[key];
            if (symbol.name.toLowerCase().indexOf(searchValue) >= 0) {
                list.push({...symbol, ...{symbol: symbol.name}});
            }
        });

        onResultReadyCallback(list);
    };

    calculateHistoryDepth = (resolution, resolutionBack, intervalBack) => {
    };

    addParamsToQueueRequest = ({reqId, periodM, periodB, from, to}, symbolInfo, resolution, subscriberUID) => {
        try {
            const requestParams = this._requestQueue[symbolInfo.symbol][resolution][subscriberUID].requestParams;

            if (!requestParams.length) {
                this._requestQueue[symbolInfo.symbol][resolution][subscriberUID].requestParams = [];
                this._requestQueue[symbolInfo.symbol][resolution][subscriberUID].requestParams.push({
                    reqId, symbolId: symbolInfo.symbol, periodM, periodB, from, to
                });
            } else {
                if (requestParams[requestParams.length - 1].from !== from)
                    this._requestQueue[symbolInfo.symbol][resolution][subscriberUID].requestParams.push({
                        reqId, symbolId: symbolInfo.symbol, periodM, periodB, from, to
                    });
            }
            this.getNextQueueRequest(symbolInfo, resolution, subscriberUID);
        } catch (e) {
        }
    };

    getNextQueueRequest = (symbolInfo, resolution, subscriberUID) => {
       try {
           const {requestParams, isRequestActive} = this._requestQueue[symbolInfo.symbol][resolution][subscriberUID];

           if (requestParams.length === 0) return;

           if (!isRequestActive) {
               this.setHistoryRequestStatus(symbolInfo, resolution, subscriberUID, true);
               this.onGetBars({
                   reqId: requestParams[0].reqId,
                   symbolId: requestParams[0].symbolId,
                   periodM: requestParams[0].periodM,
                   periodB: requestParams[0].periodB,
                   from: requestParams[0].from,
                   to: requestParams[0].to
               });
               this.setHistoryRequestTimeout(symbolInfo, resolution, subscriberUID);
           }
       } catch (e) {
       }
    };

    setHistoryRequestStatus = (symbolInfo, resolution, subscriberUID, value = false) => {
        if (value)
            this._requestQueue[symbolInfo.symbol][resolution][subscriberUID].isRequestActive = value;
        else
            this.clearHistoryRequestTimeout(symbolInfo, resolution, subscriberUID);
    };

    setHistoryRequestTimeout = (symbolInfo, resolution, subscriberUID) => {
        try {
            if (this._requestQueue[symbolInfo.symbol][resolution][subscriberUID].timoutId)
                clearTimeout(this._requestQueue[symbolInfo.symbol][resolution][subscriberUID].timoutId);

            this._requestQueue[symbolInfo.symbol][resolution][subscriberUID].timoutId = setTimeout(
                () => {
                    this.clearHistoryRequestTimeout(symbolInfo, resolution, subscriberUID);
                }, this.historyRequestTimeout);
        } catch (e) {
        }
    };

    clearHistoryRequestTimeout = (symbolInfo, resolution, subscriberUID) => {
        try {
            if (this._requestQueue[symbolInfo.symbol][resolution][subscriberUID])
                clearTimeout(this._requestQueue[symbolInfo.symbol][resolution][subscriberUID].timoutId);
            this._requestQueue[symbolInfo.symbol][resolution][subscriberUID].requestParams.shift();
            this._requestQueue[symbolInfo.symbol][resolution][subscriberUID].isRequestActive = false;
            this._requestQueue[symbolInfo.symbol][resolution][subscriberUID].timoutId = null;

            this.getNextQueueRequest(symbolInfo, resolution, subscriberUID);
        } catch (e) {}
    };

    getBars(symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback, firstDataRequest = false, subscriberUID = undefined) {
        let units = TmxDataFeed.mapunits[resolution];
        if (typeof units === "undefined") {
            units = 1
        }

        const periodM = units;
        let periodB = 60;

        if (resolution.slice(-1) === TmxDataFeed.MODE_SECOND) {
            periodB = 1;
        }
        if (resolution.slice(-1) === TmxDataFeed.MODE_TICK) {
            periodB = 0;
        }
        if (units < 0 || units >= 3600) {
            periodB *= 60;
        }
        const reqId = Date.now() + Math.ceil(Math.random() * 100000000);
        this._historyCallbacks[reqId] = {
            resolution,
            handler: onHistoryCallback,
            errorHandler: onErrorCallback,
            isFirstDataRequest: firstDataRequest
        };

        if (+from === +to) {
            from = from - 60;
        }

        if (subscriberUID)
            this.addParamsToQueueRequest({reqId, periodM, periodB, from, to}, symbolInfo, resolution, subscriberUID);
        else
            this.onGetBars({reqId, symbolId: symbolInfo.symbol, periodM, periodB, from, to});

    }

    gotBars({reqId, reqTime, list, symbolId}) {
        if (!this._historyCallbacks[reqId]) {
            return;
        }

        const { noGapBars } = AppConfig.stockChart;
        const dataHistory = this._historyCallbacks[reqId];
        const emptyList = list.length === 0;

        const data = emptyList
            ? {
                "nodata": emptyList, // there isn`t documentation about key name
                "noData": emptyList
            }
            : {
                "nodata": emptyList, // there isn`t documentation about key name
                "noData": emptyList
            };

        const historyGotBarsLastRequestKey = HistoryGotBarsLastRequest.getKey(symbolId, dataHistory.resolution);
        let historyGotBarsLastRequest = this._historyGotBarsLastRequest[historyGotBarsLastRequestKey];
        if (!historyGotBarsLastRequest) {
            historyGotBarsLastRequest = new HistoryGotBarsLastRequest();
            this._historyGotBarsLastRequest[historyGotBarsLastRequestKey] = historyGotBarsLastRequest;
        }

        historyGotBarsLastRequest.gotBars({lastRequestTime: reqTime, emptyData: emptyList});

        if (emptyList && historyGotBarsLastRequest.isTryGetMore(reqTime)) {
            data["nextTime"] = reqTime - 60000; //1min before
        }

        const barsList = list.map((quote, index) => {
            const open = (index && noGapBars) ? list[index - 1].close : quote.open;

            return {
                ...quote,
                open,
            }
        });

        dataHistory.handler(barsList, data);

        if (!this._lastSymbolsBars[symbolId]) {
            this._lastSymbolsBars[symbolId] = barsList[barsList.length - 1] || {};
        }

        delete this._historyCallbacks[reqId];
    }

    updateQuote(quote) {
        if (!this._lastSymbolsBars[quote.SymbolId] || !this._symbols[quote.SymbolId]) {
            return;
        }

        const quoteValue = this._showValue === TmxDataFeed.SHOW_BID ? quote.Bid : quote.Ask;

        if (quoteValue > this._symbols[quote.SymbolId].priceStep) {
            let lastBar = this._lastSymbolsBars[quote.SymbolId];
            const qt = quote.DateTime * 1000;

            if (lastBar && lastBar.time > qt) {
                return;
            }

            if (!lastBar || lastBar.time < qt) {
                lastBar = {
                    time: qt,
                    close: quoteValue,
                    open: quoteValue,
                    high: quoteValue,
                    low: quoteValue,
                    volume: 1
                };
                this._lastSymbolsBars[quote.SymbolId] = lastBar;
            } else {
                if (lastBar.high < quoteValue) {
                    lastBar.high = quoteValue;
                }
                if (lastBar.low > quoteValue) {
                    lastBar.low = quoteValue;
                }
                lastBar.close = quoteValue;
                lastBar.volume++;
            }

            if (this._chartSubscribes[quote.SymbolId] && this._chartSubscribes[quote.SymbolId]) {
                for (let resolution in this._chartSubscribes[quote.SymbolId]) {
                    const subscribesResolutions = this._chartSubscribes[quote.SymbolId][resolution];
                    for (let uid in subscribesResolutions) {
                        const tickLastBar = {
                            time: qt,
                            ask: quote.Ask,
                            bid: quote.Bid
                        };

                        const lBar = resolution === TmxDataFeed.MODE_TICK ? tickLastBar : lastBar;
                        subscribesResolutions[uid]({...lBar});
                    }
                }
            }
        }
    }

    closeBars(symbolId) {
        delete  this._lastSymbolsBars[symbolId];
        delete  this._chartSubscribes[symbolId];
    }

    getMarks(symbolInfo, startDate, endDate, onDataCallback, resolution) {
    };

    getTimescaleMarks(symbolInfo, startDate, endDate, onDataCallback, resolution) {
    };

    getServerTime(callback) {
    };

    subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
        if (!this._chartSubscribes[symbolInfo.symbol])
            this._chartSubscribes[symbolInfo.symbol] = {};

        if (!this._chartSubscribes[symbolInfo.symbol][resolution])
            this._chartSubscribes[symbolInfo.symbol][resolution] = {};

        this._chartSubscribes[symbolInfo.symbol][resolution][subscriberUID] = onRealtimeCallback;

        if (!this._requestQueue[symbolInfo.symbol])
            this._requestQueue[symbolInfo.symbol] = {};

        if (!this._requestQueue[symbolInfo.symbol][resolution])
            this._requestQueue[symbolInfo.symbol][resolution] = {};

        this._requestQueue[symbolInfo.symbol][resolution][subscriberUID] = requestInit;
    };

    unsubscribeBars(subscriberUID) {
        //delete this.subscribes[ticker][subscriberUID];
        const unsubscribeFunction = (object) => {
            Object.keys(object).forEach((ticker) => {
                Object.keys(object[ticker]).forEach((resolution) => {
                    Object.keys(object[ticker][resolution]).forEach((uid) => {
                        if (uid === subscriberUID)
                            delete object[ticker][resolution][uid];
                    });

                    if (Object.keys(object[ticker][resolution]).length === 0)
                        delete object[ticker][resolution];
                });

                if (Object.keys(object[ticker]).length === 0)
                    delete object[ticker];
            });
        };
        unsubscribeFunction(this._chartSubscribes);
        unsubscribeFunction(this._requestQueue);
    };

    convertQuotesToPeriod(data, period) {
        const listQuotes = data.ListQuotes;
        let unitedQuotes = [];

        if (!Array.isArray(listQuotes) || !listQuotes.length) return unitedQuotes;

        const startTime = data.StartTime;
        const endTime = data.EndTime;
        let timeStep = (period === -1) ? 1000 * 60 * 60 * 24 * moment(startTime).daysInMonth() : period * 1000;

        let currentBarTime = startTime;
        let nextBarTime = startTime + timeStep;
        
        listQuotes.forEach((quote) => {
            const quoteTime = Math.floor(quote.time / timeStep) * timeStep;

            if (quoteTime < nextBarTime) { // merge quotes
                if (!unitedQuotes.length) {//have no first quote
                    unitedQuotes.push({ ...quote, time: currentBarTime });
                    return;
                }

                const currentBar = unitedQuotes[unitedQuotes.length - 1];

                unitedQuotes[unitedQuotes.length - 1] = {
                    ...currentBar,
                    high: currentBar.high < quote.high ? quote.high : currentBar.high,
                    low: currentBar.low > quote.low ?  quote.low : currentBar.low,
                    close: quote.close,
                    time: currentBarTime,
                    volume: currentBar.volume += quote.volume ? quote.volume : 1
                }
            } else if (quoteTime <= endTime) {
                while (nextBarTime <= quoteTime && nextBarTime < endTime) {// if have not quote in currentPeriod - skip period
                    timeStep = (period === -1) ? 1000 * 60 * 60 * 24 * moment(quoteTime).daysInMonth() : timeStep;
                    nextBarTime += timeStep;
                }

                currentBarTime = nextBarTime - timeStep;

                unitedQuotes.push({
                    ...quote,
                    time: currentBarTime,
                });
            }
        });
        
        return unitedQuotes;
    }
}

class TmxDataFeedSymbol {
    symbol;
    full_name;
    type = "forex";
    name;
    ticker;
    description;
    session = "24x7";
    exchange = "FX";
    timezone = "UTC";
    pricescale = 100;
    minmov = 0.00001;
    fractional = true;
    minmove2 = 0;
    has_intraday = true;
    supported_resolutions = ['10S', '1', '5', '15', '30', '60', '240', 'D', 'W', 'M'];
    intraday_multipliers = ['1', '5', '15', '30', '60', '240'];
    has_seconds = true;
    seconds_multipliers = ['10'];
    has_daily = true;
    has_weekly_and_monthly = true;
    has_empty_bars = true;
    force_session_rebuild = false;
    has_no_volume = false;
    volume_precision = 0;
    data_status = "streaming";
    expired = false;
    expiration_date = "";
    sector = "";
    industry = "";
    currency_code;

    //addition data
    priceStep = 0;

    static createFromTicker(ticker) {
        const symbol = new TmxDataFeedSymbol();
        symbol.symbol = ticker.SymbolId;
        symbol.name = ticker.Ticker;
        symbol.description = ticker.Ticker;
        symbol.full_name = ticker.SymbolId;
        symbol.ticker = ticker.SymbolId;
        symbol.currency_code = ticker.Exp2;
        symbol.priceStep = ticker.PriceStep;

        return symbol;
    }
}

class HistoryGotBarsLastRequest {
    static NOT_DOWNLOAD_DATA_AFTER_TIMES = 3; // count times try get new empty data

    _lastRequestTime;
    _gotEmptyDataTimes = 0;

    static getKey(symbolId, resulution) {
        return `${symbolId}_${resulution}`
    }

    gotBars({lastRequestTime, emptyData}) {
        if (!this._lastRequestTime || lastRequestTime < this._lastRequestTime) {
            this._lastRequestTime = lastRequestTime;

            if (emptyData) {
                this._gotEmptyDataTimes++;
            } else {
                this._gotEmptyDataTimes = 0;
            }
        }
    }

    isTryGetMore(time) {
        if (time > this._lastRequestTime) {
            return true;
        }

        return this._gotEmptyDataTimes <= HistoryGotBarsLastRequest.NOT_DOWNLOAD_DATA_AFTER_TIMES;
    }
}

export default TmxDataFeed;

export {
    TmxDataFeed,
    TmxDataFeedSymbol,
}